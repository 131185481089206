import styled from "styled-components";
import { UnStyledButton } from "../../styles/buttons/UnStyledButton";

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.3);
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: visibility 0s, opacity 0.5s;
`;

export const Content = styled.div<{ isContentOpen: boolean }>`
  background: var(--off-white);
  box-shadow: var(--sharp-shadow);
  background-size: 100%;
  border-radius: 5px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-height: 750px) {
    height: ${({ isContentOpen }) => (isContentOpen ? "80vh" : "inherit")};
    padding-bottom: ${({ isContentOpen }) =>
      isContentOpen ? "20px" : "inherit"};
  }
`;

export const Header = styled.div<{ isLabelPresent?: boolean }>`
  width: 100%;
  background: var(--content-color);
  color: black;
  position: relative;
  padding: 5px 0px 5px 10px;
  box-shadow: var(--light-shadow);
  display: flex;
  justify-content: ${({ isLabelPresent }) =>
    isLabelPresent ? "space-between" : "flex-end"};
  gap: 50px;
  align-content: center;
  font-weight: bold;
  font-size: 1.2em;
`;

export const CloseButton = styled(UnStyledButton)`
  transition: 0.2s;
  &:hover {
    color: #484848;
  }
`;
