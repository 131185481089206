import styled from "styled-components";
import { UnStyledButton } from "./UnStyledButton";

interface IHamburgerButton {
  callback: React.MouseEventHandler;
  state: boolean;
}

const HamburgerButton = ({ callback, state }: IHamburgerButton) => {
  return (
    <BurgerButtonWrapper onClick={callback}>
      <TopLine state={state} />
      <MiddleLine state={state} />
      <BottomLine state={state} />
    </BurgerButtonWrapper>
  );
};

const MenuLine = styled.div<{ state: boolean }>`
  width: ${({ state }) => (state ? "70%" : "80%")};
  height: 2px;
  border-radius: 3px;
  background: black;
  transition: transform 500ms ease, opacity 300ms ease;
`;

const TopLine = styled(MenuLine)`
  transform: ${({ state }) =>
    state ? "translateY(7px) rotate(225deg)" : "translateY(0px) rotate(0deg)"};
`;

const MiddleLine = styled(MenuLine)`
  opacity: ${({ state }) => (state ? 0 : 1)};
`;

const BottomLine = styled(MenuLine)`
  transform: ${({ state }) =>
    state
      ? "translateY(-7px) rotate(-225deg)"
      : "translateY(0px) rotate(0deg)"};
`;

const BurgerButtonWrapper = styled(UnStyledButton)`
  margin-left: 6px;
  height: 100%;
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 5px;
  padding: 0;
`;

export default HamburgerButton;
