import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { CloseButton } from "../../footer/emailform.styles";

interface ICloseFormField {
  callback?: () => void;
}

const CloseFormField = ({ callback }: ICloseFormField) => {
  const { t } = useTranslation(["reservationForm"]);
  const handleFormClose = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    callback?.();
  };

  return (
    <CloseButton onClick={handleFormClose}>
      {t("reservationForm:cancel")}
    </CloseButton>
  );
};

export default CloseFormField;
