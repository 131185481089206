import { useContext, useEffect } from "react";
import { ReservationContext } from "../../contexts/ReservationContext";

//components
import Header from "../header/Header";
import FrequentlyAskedQuestions from "../frequently-asked-questions/FrequentlyAskedQuestions";
import Packages from "../price-display/Packages";
import Reviews from "../reviews/Reviews";
import Footer from "../footer/Footer";
import { LineBorder } from "./main-page.styles";

const MainPage = () => {
  const { reservation } = useContext(ReservationContext);

  const body = document.querySelector("body");

  useEffect(() => {
    if (body) {
      if (!!reservation) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [reservation]);
  return (
    <>
      <Header />
      <LineBorder />
      <Packages />
      <FrequentlyAskedQuestions />
      <Reviews />
      <Footer />
    </>
  );
};

export default MainPage;
