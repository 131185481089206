import styled from "styled-components";
import { UnStyledHyperLink } from "./UnstyledHyperLink";

export const MainStyledLink = styled(UnStyledHyperLink)`
  background: var(--heighlight-color);
  padding: 9px 20px;
  border-radius: 3px;
  box-shadow: var(--light-shadow);
  transition: 300ms ease;
  font-size: 15px;
  text-shadow: 1px 1px 3px #505050;
  color: #fff;
  text-align: center;
  text-decoration: none;
  :hover {
    transform: scale(1.03);
  }
`;
