import styled from "styled-components";
import { MainStyledButton } from "../styles/buttons/MainStyledButton";
import { UnStyledButton } from "../styles/buttons/UnStyledButton";

export const FormWrapper = styled.form`
  background: #ebebebc7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  border-radius: 2px;
  box-shadow: var(--light-shadow);
  flex-grow: 1;
  max-width: 550px;
  padding-top: 50px;
  @media (max-width: 760px) {
    padding: 15px;
    padding-top: 25px;
  }
`;

export const EmailInput = styled.input`
  border: 1px solid #adadad;
  border-radius: 4px;
  outline: none;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
`;

export const Label = styled.label``;

export const MessageTextArea = styled.textarea`
  resize: none;
  border: 1px solid #adadad;
  border-radius: 4px;
  outline: none;
  padding: 5px;
  padding-left: 10px;
  height: 200px;
  overflow: auto;
  margin-bottom: 15px;
`;

export const SubmitButton = styled(MainStyledButton)`
  box-shadow: none;
  width: 100%;
  :disabled {
    cursor: default;
    background: #6d6d6d;
    :hover {
      transform: scale(1);
    }
  }
`;

export const CloseButton = styled(SubmitButton)`
  background: #750303;
  opacity: 0.7;
  transition: 0.2s ease;
  :hover {
    opacity: 1;
  }
`;

export const FormFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;

export const EditToggleButton = styled(UnStyledButton)<{ isEditing: boolean }>`
  padding: 0;
  position: absolute;
  right: 3px;
  top: -15px;
  font-size: 1.3em;
  color: ${({ isEditing }) =>
    isEditing ? "var(--heighlight-color)" : "black"};
  transition: 0.2s ease;
  :hover {
    transform: rotate(-10deg);
  }
  :active {
    transform: scale(0.5);
  }
`;
