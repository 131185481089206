import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowsExpand } from "react-icons/hi";
//styles
import {
  PackageBody,
  PackageFooter,
  PackageHeader,
  PackageImage,
  PackageImageWrapper,
  PackageInfoWrapper as Wrapper,
  PackageName,
  PackagePrice,
  PackageNameAndPrice,
  BookNowButton,
  PackageTitle,
} from "./packageInfo.styles";

// types
import { ISiteItem } from "../../../assets/items";

// components
import { ReservationContext } from "../../../contexts/ReservationContext";
import InfoStats from "./InfoStats";
import PriceTag from "../PriceTag.animated";
import Hours from "./Hours";
import { UnStyledButton } from "../../styles/buttons/UnStyledButton";

interface IPackageInfo {
  item: ISiteItem;
}

const PackageInfo = ({ item }: IPackageInfo) => {
  const {
    id,
    title,
    name,
    size,
    img,
    horsePower,
    passangers,
    prices,
    type,
    times,
  } = item;
  const { t } = useTranslation();
  const [currentHour, setCurrentHour] = useState<string>(
    times[times.length - 1].toString()
  );

  const { addReservation, selectPontoon } = useContext(ReservationContext);

  const changeCurrentHour = (value: string): void => {
    setCurrentHour(value);
  };

  return (
    <Wrapper>
      <PackageHeader>
        <UnStyledButton
          style={{ position: "relative" }}
          onClick={() => selectPontoon(img)}
        >
          <PackageImageWrapper>
            <PackageImage
              key={`${name}-${type}`}
              src={img[0]}
              alt={`${name}-${type}`}
            />
          </PackageImageWrapper>
          <HiOutlineArrowsExpand
            style={{ position: "absolute", right: "7px", top: "2px" }}
            size={24}
          />
          <HiOutlineArrowsExpand
            style={{ position: "absolute", right: "9px", top: "4px" }}
            size={20}
            color="white"
          />
        </UnStyledButton>
      </PackageHeader>
      <PackageBody>
        <PackageNameAndPrice>
          {title && <PackageTitle>{title}</PackageTitle>}
          <PackageName>{name}</PackageName>
          <PackagePrice>
            <PriceTag value={`$${prices[Number(currentHour)]}`} />
          </PackagePrice>
        </PackageNameAndPrice>
        <InfoStats
          size={size}
          horsePower={horsePower}
          passangers={passangers}
        />
      </PackageBody>
      <PackageFooter>
        <Hours
          hoursArray={times}
          callback={changeCurrentHour}
          currentHour={currentHour}
          id={id}
        />
        <BookNowButton
          onClick={() =>
            addReservation({
              ...item,
              price: prices[Number(currentHour)],
              hour: +currentHour,
            })
          }
          style={{ width: "100%", maxWidth: "400px" }}
        >
          {t("bookNow")}
        </BookNowButton>
      </PackageFooter>
    </Wrapper>
  );
};

export default PackageInfo;
