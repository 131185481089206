import styled from "styled-components";
import { MainStyledButton } from "../../styles/buttons/MainStyledButton";

export const PackageInfoWrapper = styled.div`
  border: none;
  outline: none;
  background: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0 10px 2px #d1d1d1;
  border-radius: 2px;
  padding: 2px;
  padding-bottom: 0;
  max-width: 325px;
  transition: 200ms;
  &:hover {
    box-shadow: 0 0 10px 3px #aba8a8;
  }
`;

export const PackageTitle = styled.div`
  position: absolute;
  z-index: 10;
  top: -30px;
  left: -17px;
  font-size: 1.3em;
  background: white;
  width: 112%;
  padding: 2px 10px;
  @media (max-width: 450px) {
    padding: 5px;
  }
  @media (max-width: 330px) {
    padding: 5px;
    left: -12px;
    width: 113%;
  }
`;

export const PackageName = styled.h3`
  font-size: 1.6em;
  @media (max-width: 450px) {
    font-size: 1.3em;
  }
`;

export const PackagePrice = styled.div`
  font-weight: bold;
  font-size: 1.4em;
  padding-top: 3px;
`;

export const PackageNameAndPrice = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: start;
  @media (max-width: 450px) {
    flex-direction: column;
    line-height: 1.1;
  }
`;

export const PackageStats = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 0;
  justify-self: start;
`;

export const PackageStat = styled.li`
  display: flex;

  align-items: end;
  span {
    font-weight: bold;
    margin-right: 3px;
    div {
      width: 30px;
      ul {
        width: 30px;
      }
    }
  }
`;

export const PackageImageWrapper = styled.div`
  width: 320px;
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: 330px) {
    width: 200px;
    height: 200px;
  }
`;

export const PackageImage = styled.img`
  width: 100%;
  height: auto;
`;

export const Line = styled.div`
  height: 2px;
  border-radius: 2px;
  opacity: 0.1;
  background: black;
  width: 90%;
`;

export const PackageHeader = styled.div`
  @media (max-width: 250px) {
    align-items: center;
  }
`;

export const PackageBody = styled.div`
  width: 90%;
  margin-bottom: auto;
`;

export const PackageFooter = styled.div`
  width: 102%;
  overflow: hidden;
`;

export const BookNowButton = styled(MainStyledButton)`
  border-radius: 0px;
  box-shadow: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  &:hover {
    transform: scale(1.2);
  }
`;
