import styled from "styled-components";
import { UnStyledButton } from "../../styles/buttons/UnStyledButton";

export const TextFieldWrapper = styled.div`
  margin: 18px 5px 5px 0;
  position: relative;
  width: 100%;
`;

export const InputField = styled.input`
  padding: 8px;
  margin-top: 10px;
  outline: none;
  border: none;
  border-radius: 2px;
  width: 100%;
  border-bottom: 1px solid black;

  :focus {
    border-bottom: 2px solid black;
  }
  :read-only {
    background: var(--soft-content-color);
    font-weight: bold;
    font-size: 0.9em;
    border-bottom: 2px solid black;
    cursor: default;
  }
`;

export const TextArea = styled.textarea`
  padding: 8px;
  margin-top: 10px;
  outline: none;
  border: none;
  border-radius: 2px;
  min-width: 100%;
  resize: vertical;
  min-height: 120px;
  border-bottom: 1px solid black;
  :focus {
    border-bottom: 2px solid black;
  }
  :read-only {
    background: var(--soft-content-color);
    font-weight: bold;
    font-size: 0.9em;
    border-bottom: 2px solid black;
  }
`;

interface ILabel {
  error: boolean;
  hasValue: boolean;
}

export const Label = styled.label<ILabel>`
  display: flex;
  align-items: baseline;
  overflow: hidden;
  position: absolute;
  color: ${({ error, hasValue }) =>
    error ? "#e60808" : hasValue ? "black" : "#808080"};
  z-index: 1;
  left: 5px;
  transition: 0.2s ease;
  top: ${({ error, hasValue }) => (hasValue || error ? "-16px" : "8px")};
`;

export const LabelExtraInfo = styled.div`
  font-size: 0.8em;
  text-shadow: 1px 1px 3px gray;
  padding-left: 3px;
  transition: 200ms ease;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: hidden;
  &:hover {
    color: var(--heighlight-color);
  }
  @media (max-width: 550px) {
    &:before {
      content: "-";
    }
    width: 38px;
    margin-left: 2px;
  }
`;

export const Form = styled.form<{ isOpen: boolean }>`
  padding: 10px 5px;
  min-width: 230px;
  display: flex;
  flex-direction: column;
  overflow: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: 0.2s ease;
  padding-bottom: 20px;
`;

export const DateFieldButton = styled(UnStyledButton)`
  background: white;
  margin-top: 22px;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 2px;
  min-width: 100%;
  resize: vertical;
  border-bottom: 2px solid black;
`;

export const DatePickerWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  .react-datepicker__aria-live {
    display: none;
  }
  label {
    top: -2px;
  }

  .detail_button {
    background: ${({ selected }) =>
      selected ? "white" : "var(--soft-content-color)"};
    font-weight: ${({ selected }) => (selected ? "normal" : "bold")};
    color: ${({ selected }) => (selected ? "black" : "#545454")};
  }
`;
