import styled from "styled-components";
import { GlobalWrapper } from "../styles/CommonWrapper.styles";

export const FrequentlyAskedQuestionsWrapper = styled(GlobalWrapper)`
  background: var(--off-white);
  box-shadow: var(--light-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  margin-bottom: 2%;
  @media (max-width: 760px) {
  }
`;

export const FrequentlyAskedQuestionsTitle = styled.h1`
  @media (max-width: 760px) {
    font-size: 20px;
  }
`;

export const CommonQuestionsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-areas: "question question";
  gap: 11px;
  @media (max-width: 1050px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    grid-template-areas: "question";
  }
`;

export const CommonQuestion = styled.li`
  max-width: 500px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  background: var(--content-color);
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 10px 2px #cfcfcf;
  transition: 200ms;

  &:hover {
    background: var(--soft-content-color);
    transform: scale(1.04);
  }
`;

export const MoreQuestionsBox = styled.li`
  max-width: 500px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  background: var(--content-color);
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 10px 2px #cfcfcf;
  transition: 200ms;

  &:hover {
    background: var(--soft-content-color);
    transform: scale(1.04);
  }
`;

export const MoreQuestionFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FileDownloadLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  transition: 200ms;
  padding: 3px;
  border-radius: 2px;
  &:hover {
    background: var(--content-color);
    transform: scale(1.03);
  }
`;
export const FileTitle = styled.div`
  font-size: 0.9em;
`;
