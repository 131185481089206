import React from "react";
import { VideoPlayer } from "./header.styles";
import { useSwiper, useSwiperSlide } from "swiper/react";
import FadeInAndOut from "../animations.ts/FadeInAndOut";

interface IVideo {
  onEnded: () => void;
  videoPoster: string;
  video: string;
  isPlaying: boolean;
}

const Video = ({ onEnded, videoPoster, video, isPlaying }: IVideo) => {
  const swiper = useSwiper();

  return (
    <>
      {!isPlaying && <img src={videoPoster} />}
      <FadeInAndOut state={isPlaying}>
        <VideoPlayer
          id="header-video-player"
          autoPlay
          muted
          poster={videoPoster}
          onEnded={() => {
            swiper.slideNext();
            onEnded();
          }}
        >
          <source src={video} type="video/mp4" />
        </VideoPlayer>
      </FadeInAndOut>
    </>
  );
};

export default Video;
