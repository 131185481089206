import styled from "styled-components";

export const SwipperWrapper = styled.div`
  max-width: 50vw;
  padding: 20px;
  @media (max-width: 750px) {
    max-width: 90vw;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .swiper-pagination {
      bottom: 2px !important;
    }
  }
`;

export const SwipperImage = styled.img`
  border-radius: 3px;
`;
