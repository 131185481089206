import { useTranslation } from "react-i18next";

//images
import lakeScene from "../../assets/images/lakeScene011.AVIF";
import ctaImage from "../../assets/images/CTA_image.jpg";

//styles
import {
  HeaderWrapperCTA as Wrapper,
  TextWrapper,
  HeaderDescription as Description,
  HeaderContentWrapper,
  HeaderCenterImage,
  ImageWrapper,
  HeaderDescriptionTitle,
  PriceButton,
  StyledLink,
} from "./header.styles";

//configs
import { getSiteLinks } from "../../configs/siteLink.config";

const HeaderCTA = () => {
  const { t } = useTranslation();

  const rentSiteLink = getSiteLinks(t).pricesLink;

  return (
    <Wrapper>
      <HeaderContentWrapper>
        <ImageWrapper>
          <HeaderCenterImage
            src={ctaImage}
            alt="image of a lake with a crane"
          />
        </ImageWrapper>
        <TextWrapper>
          <HeaderDescriptionTitle>
            {t("headerDescriptionTitle")}
          </HeaderDescriptionTitle>
          <Description>{t("headerIntroParagraphP1")}</Description>
          <Description>{t("headerIntroParagraphP2")}</Description>
          <Description>
            {t("headerIntroParagraphP3")}
            <div style={{ marginLeft: "10px" }}> • {t("P3point1")}</div>
            <div style={{ marginLeft: "10px" }}> • {t("P3point2")}</div>
          </Description>
          <StyledLink smooth to={rentSiteLink.targetId}>
            <PriceButton>{t("rentNow")}</PriceButton>
          </StyledLink>
        </TextWrapper>
      </HeaderContentWrapper>
    </Wrapper>
  );
};

export default HeaderCTA;
