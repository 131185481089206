import ReactDOM from "react-dom";
import App from "./App";

import "./i18n";
import ReservationProvider from "./contexts/ReservationContext";
import FormProvider from "./contexts/FormContext";

ReactDOM.render(
  <FormProvider>
    <ReservationProvider>
      <App />
    </ReservationProvider>
  </FormProvider>,
  document.getElementById("root")
);
