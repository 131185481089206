import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
//configs
import { getSiteLinks } from "../../configs/siteLink.config";
import { items, ISiteItem } from "../../assets/items";
import { GiPalmTree } from "react-icons/gi";
//components
import { SectionIndicator } from "../styles/SectionIndicator";
import ReservationModal from "../common-components/modals/reservation-modal/ReservationModal";
import PackageInfo from "./packageInfo/PackageInfo";
import ImageDisplayModal from "../common-components/modals/image-display-modal/ImageDisplayModal";

//style
import {
  PackageWrapper as Wrapper,
  PackageTitle as Title,
  SmallText,
  PackagesWrapper,
  ObviousText,
} from "./package.style";

const Packages = () => {
  const { t } = useTranslation();

  const siteItems: ISiteItem[] = items;

  const pricesSiteLink = getSiteLinks(t).pricesLink;
  return (
    <>
      <Wrapper id={pricesSiteLink.targetId}>
        <SectionIndicator>{t("prices").toUpperCase()}</SectionIndicator>
        <Title>{t("pricesTitle")}</Title>
        <ObviousText>
          <GiPalmTree
            style={{
              display: "inline",
              transform: "scale3d(-1.5, 1.5, 1.5)",
              margin: " 8px 5px 0 0",
            }}
          />
          {t("promotionText")}
          <GiPalmTree
            style={{
              display: "inline",
              transform: "scale3d(1.5, 1.5, 1.5)",
              margin: " 8px 0 0 5px",
            }}
          />
        </ObviousText>
        <PackagesWrapper>
          {siteItems.map((item, index) => (
            <PackageInfo key={`${item.name}-${index}-${item.id}`} item={item} />
          ))}
        </PackagesWrapper>
      </Wrapper>
      <ReservationModal />
      <ImageDisplayModal />
      <ToastContainer />
    </>
  );
};

export default Packages;
