import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languagedetector from "i18next-browser-languagedetector";

import enCommon from "./assets/locales/en/common.json";
import frCommon from "./assets/locales/fr/common.json";
import enReservationForm from "./assets/locales/en/reservationForm.json";
import frReservationForm from "./assets/locales/fr/reservationForm.json";

export const resources = {
  en: { common: enCommon, reservationForm: enReservationForm },
  fr: { common: frCommon, reservationForm: frReservationForm },
};

i18n.use(initReactI18next).init({
  resources,
  preload: ["fr", "en"],
  whitelist: ["fr", "en"],
  ns: ["common"],
  fallbackNS: "common",
  fallbackLng: "fr",
  // debug: true,
  react: {
    useSuspense: true,
    wait: true,
  },
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["path", "navigator"],
    lookupFromPathIndex: 0,
    checkWhitelist: true,
  },
});

export default i18n;
