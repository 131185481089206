import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { ReservationContext } from "../../../../contexts/ReservationContext";
import Modal from "../Modal";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SwipperImage, SwipperWrapper } from "./imageDisplayModal.styles";
const ImageDisplayModal = () => {
  const { pontoonImages, removePontoon } = useContext(ReservationContext);

  return (
    <Modal isOpen={pontoonImages.length > 0} handleCloseModal={removePontoon}>
      <SwipperWrapper>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop
          pagination={{ clickable: true }}
          navigation
          modules={[Pagination, Navigation]}
        >
          {pontoonImages.map((image, index) => (
            <SwiperSlide>
              <SwipperImage key={`${index}`} src={image} alt={`${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwipperWrapper>
    </Modal>
  );
};

export default ImageDisplayModal;
