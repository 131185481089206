export const formatString = (string: string): string[] => {
  if (string.length > 20) {
    if (string.includes(",")) {
      return string.split(",");
    }
    if (string.includes("450")) {
      return formatPhoneNumber(string);
    } else {
      return string.split(" ");
    }
  } else {
    return [string];
  }
};

const formatPhoneNumber = (string: string): string[] => {
  let phoneNumbers: string[] = [];
  if (string.includes("or")) {
    phoneNumbers = string.split("or");
    phoneNumbers.splice(1, 0, "or");
  }
  if (string.includes("ou")) {
    phoneNumbers = string.split("ou");
    phoneNumbers.splice(1, 0, "ou");
  }
  return phoneNumbers;
};
