import { useState } from "react";
//styles
import { VideoWrapper as Wrapper } from "./header.styles";
import video from "../../assets/video/backgroundVideo.mp4";
import videoTwo from "../../assets/video/backgroundVideo2.mp4";
import videoPoster from "../../assets/images/videoStartScreenshot.AVIF";
import videoPosterTwo from "../../assets/images/videoStartScreenshot2.AVIF";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Video from "./Video";

const BackgroundVideo = () => {
  const [playingFirstVideo, setPlayingFirstVideo] = useState(true);

  const handleVideoEnd = () => {
    setPlayingFirstVideo(!playingFirstVideo);
  };

  const videos = [
    <Video
      onEnded={handleVideoEnd}
      video={video}
      videoPoster={videoPoster}
      isPlaying={playingFirstVideo}
    />,
    <Video
      onEnded={handleVideoEnd}
      video={videoTwo}
      videoPoster={videoPosterTwo}
      isPlaying={!playingFirstVideo}
    />,
  ];

  return (
    <Wrapper>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        pagination={{ clickable: true }}
        navigation
        modules={[Pagination, Navigation]}
        onSlideChange={(swiper) => {
          setPlayingFirstVideo(swiper.activeIndex % 2 !== 0);
        }}
      >
        {videos.map((video) => (
          <SwiperSlide>
            <Wrapper>{video}</Wrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export default BackgroundVideo;
