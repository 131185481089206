import styled from "styled-components";

export const Wrapper = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  margin: 15px 5px 5px 5px;
  position: relative;
  width: 100%;
`;
