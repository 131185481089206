import { IsiteLinksObject } from "../content/navigation-bar/siteLinks/Links";

export const getSiteLinks = (t: Function): IsiteLinksObject => {
  return {
    pricesLink: { name: t("prices"), targetId: "prices-section" },
    productsLink: { name: t("faq"), targetId: "faq-section" },
    //aboutUsLink: { name: t("aboutUs"), targetId: "aboutUs-section" },
    contactLink: { name: t("contact"), targetId: "contact-section" },
  };
};
