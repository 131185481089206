import { useTranslation } from "react-i18next";

//styles
import { LinksWrapper as Wrapper } from "./link.styles";

//config
import { getSiteLinks } from "../../../configs/siteLink.config";

//components
import Link from "./Link";

export interface IsiteLinks {
  name: string;
  targetId: string;
  callback?: Function;
}

export interface IsiteLinksObject {
  pricesLink: IsiteLinks;
  productsLink: IsiteLinks;
  //aboutUsLink: IsiteLinks;
  contactLink: IsiteLinks;
}

const Links = () => {
  const { t } = useTranslation();

  const siteLinks: IsiteLinksObject = getSiteLinks(t);

  return (
    <Wrapper>
      {Object.values(siteLinks).map(({ name, targetId }) => (
        <Link key={name} name={name} targetId={targetId} />
      ))}
    </Wrapper>
  );
};

export default Links;
