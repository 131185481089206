import styled from "styled-components";

export const HoursWrapper = styled.div``;

export const HoursTitle = styled.h3`
  padding: 2px 0;
  box-shadow: 0 3px 3px 1px #aaaaaa86;
  margin-top: 3px;
  border-bottom: solid 2px black;
  background: #f5f5f5;
`;

export const HoursList = styled.div`
  display: flex;
  flex-direction: column;
  input {
    display: none;
  }
  position: relative;
`;

export const SelectionIndicator = styled.div<{ isTopSelected: boolean }>`
  transition: 200ms ease;
  position: absolute;
  top: ${({ isTopSelected }) => (isTopSelected ? "0%" : "50%")};
  padding: 3px;
  width: 100%;
  background: var(--soft-content-color);
  color: var(--soft-content-color);
  z-index: -1;
`;

export const TimeToggle = styled.input``;
export const HourLabel = styled.label<{ selected: boolean }>`
  padding: 3px 0;
  transition: 300ms ease;
  font-weight: ${({ selected }) => selected && "bold"};
  background: transparent;
  &:hover {
    background: var(--content-color);
  }
`;
