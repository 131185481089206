import { FormEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

//styles
import {
  FormWrapper as Form,
  EmailInput,
  MessageTextArea,
  SubmitButton,
  Label,
} from "./emailform.styles";

const initialFormValues = {
  email: "",
  message: "",
};

const EmailForm = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialFormValues);
  const [sending, setSending] = useState(false);

  const form = useRef() as any;

  const submitForm = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (form.current) {
      setSending(true);
      const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
      const serviceKey = process.env.REACT_APP_EMAIL_SERVICE_KEY;
      const templateKey = process.env.REACT_APP_CONTACT_US_EMAIL_TEMPLATE_KEY;
      if (publicKey && serviceKey && templateKey) {
        const promise = emailjs.sendForm(
          serviceKey,
          templateKey,
          form.current,
          publicKey
        );
        toast.promise(promise, {
          pending: t("sending"),
          success: `${t("sent")} 👌`,
          error: "ERROR",
        });
        await promise;
      }

      setSending(false);
      setFormValues(initialFormValues);
    }
  };

  return (
    <Form
      onSubmit={(ev) => {
        submitForm(ev);
      }}
      ref={form}
    >
      <Label htmlFor="email">{t("emailLabel")}</Label>
      <EmailInput
        id="email"
        name="email"
        type="email"
        value={formValues.email}
        onChange={(ev) =>
          setFormValues({ ...formValues, email: ev.target.value })
        }
      />
      <Label htmlFor="message">{t("messageLabel")}</Label>
      <MessageTextArea
        id="message"
        name="message"
        value={formValues.message}
        onChange={(ev) =>
          setFormValues({ ...formValues, message: ev.target.value })
        }
      />
      <SubmitButton
        disabled={
          sending || Object.values(formValues).some((key) => key.length < 10)
        }
      >
        {t("footerSubmit")}
      </SubmitButton>
    </Form>
  );
};

export default EmailForm;
