import pontoon000 from "./images/pontoon000.AVIF";
import pontoon004 from "./images/pontoon004.AVIF";
import pontoon006 from "./images/pontoon006.AVIF";
import sportFisher002 from "./images/sportFisher002.AVIF";
import vectra001 from "./images/vectra001.AVIF";
import pontoon6 from "./images/pontoon6.jpg";
import kayakSingle000 from "./images/kayak-1p.jpg";
import kayakDouble000 from "./images/kayak-2p.jpg";

import {
  pontoon1,
  pontoon2,
  pontoon3,
  pontoon4,
  pontoon5,
  pontoon7,
} from "./photoArrays";

export enum WATERCRAFT_TYPES {
  KAYAK = "KAYAK",
  PONTOON = "PONTOON",
}

export interface ISiteItem {
  id: number;
  title?: string;
  name: string;
  size: number | null;
  img: string[];
  horsePower: number | null;
  passangers: number;
  prices: Record<number, number>;
  type: WATERCRAFT_TYPES;
  times: number[];
}

export const items: ISiteItem[] = [
  {
    id: 1,
    title: "#1 Princecraft",
    name: "Sportfisher",
    size: 21,
    img: [...pontoon1],
    horsePower: 60,
    passangers: 10,
    prices: { 4: 375, 8: 525 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 2,
    title: "#2 Princecraft",
    name: "Sportfisher",
    size: 21,
    img: [...pontoon2],
    horsePower: 90,
    passangers: 10,
    prices: { 4: 400, 8: 550 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 3,
    title: "#3 Princecraft",
    name: "Sportfisher",
    size: 21,
    img: [...pontoon3],
    horsePower: 60,
    passangers: 10,
    prices: { 4: 375, 8: 525 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 4,
    title: "#4 Princecraft",
    name: "Vectra",
    size: 21,
    img: [...pontoon4],
    horsePower: 90,
    passangers: 10,
    prices: { 4: 400, 8: 550 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 5,
    title: "#5 Harris",
    name: "Cruiser CX",
    size: 22,
    img: [...pontoon5],
    horsePower: 115,
    passangers: 12,
    prices: { 4: 425, 8: 575 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 6,
    title: "#6 Princecraft",
    name: "Vectra",
    size: 23,
    img: [pontoon6],
    horsePower: 90,
    passangers: 12,
    prices: { 4: 425, 8: 575 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 7,
    title: "#7 Princecraft",
    name: "Vectra",
    size: 23,
    img: [...pontoon7],
    horsePower: 115,
    passangers: 12,
    prices: { 4: 475, 8: 625 },
    type: WATERCRAFT_TYPES.PONTOON,
    times: [4, 8],
  },
  {
    id: 91,
    name: "Kayak",
    size: null,
    img: [kayakSingle000],
    horsePower: null,
    passangers: 1,
    prices: { 24: 50 },
    type: WATERCRAFT_TYPES.KAYAK,
    times: [24],
  },
  {
    id: 92,
    name: "Kayak",
    size: null,
    img: [kayakDouble000],
    horsePower: null,
    passangers: 2,
    prices: { 24: 75 },
    type: WATERCRAFT_TYPES.KAYAK,
    times: [24],
  },
];
