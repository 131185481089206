import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DropDown, { IDropDownItem } from "../../common-components/DropDown";

// styles
import { PackageStats as Wrapper, PackageStat } from "./packageInfo.styles";

interface IInfoStatsProps {
  size: number | null;
  horsePower: number | null;
  passangers: number;
}

const InfoStats = ({ size, horsePower, passangers }: IInfoStatsProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {size && (
        <PackageStat>
          <span>{size} </span>
          {t("size")}
        </PackageStat>
      )}
      <PackageStat>
        <span>{passangers} </span>
        {t("passangers")}
        {passangers === 1 ? "" : "s"}
      </PackageStat>
      {horsePower && (
        <PackageStat>
          <span>{horsePower} </span>
          {t("horsePower")}
        </PackageStat>
      )}
    </Wrapper>
  );
};

export default InfoStats;
