import { TFunction } from "react-i18next";
import { IFormValues } from "../contexts/FormContext";
import { IReservation } from "../contexts/ReservationContext";
import { WATERCRAFT_TYPES } from "../assets/items";

export const formulateReservationName = (
  t: TFunction<"translation", undefined>,
  reservation: IReservation
) => {
  if (reservation.type === WATERCRAFT_TYPES.PONTOON) {
    return `${reservation.title} - ${reservation.name}`;
  }

  if (reservation.type === WATERCRAFT_TYPES.KAYAK) {
    const string = `${reservation.name} ${reservation.passangers} ${t(
      "passangers"
    )}${reservation.passangers === 1 ? "" : "s"}`;
    return string;
  }

  return "";
};

export const formulateReservationMessage = (
  t: TFunction<"translation", undefined>,
  reservation: IReservation
): string => {
  const string = t("reservationForm:initialMessage", {
    boat: formulateReservationName(t, reservation),
    hours: reservation.hour,
  });

  return string;
};
