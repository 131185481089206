import { IconType } from "react-icons";
import { RiGpsLine } from "react-icons/ri";
import { BiRadio } from "react-icons/bi";
import { MdOutlineDirectionsBoatFilled } from "react-icons/md";
import {
  GiTable,
  GiCaptainHatProfile,
  GiSittingDog,
  GiFishing,
} from "react-icons/gi";
import { FaSwimmingPool, FaVest, FaCaravan } from "react-icons/fa";
import { AiFillIdcard } from "react-icons/ai";
import { BsFileEarmarkPdf, BsBoxArrowInUpRight } from "react-icons/bs";

import questionsPdf from "../../assets/pdfs/questionsFréquentes.pdf";
import rulesAndConditionsPdf from "../../assets/pdfs/règlementsEtConditions.pdf";

export type ILinkQuestion = Omit<ICommonQuestion, "link"> & { target: string };
export type ILinkTranslated = Omit<ITranslatedCommonQuestion, "link"> & {
  target: string;
};

interface ICommonQuestion {
  fr: string;
  en: string;
  icon: IconType;
  link?: ILinkQuestion;
}

interface ITranslatedCommonQuestion {
  content: string;
  icon: IconType;
  link?: ILinkTranslated;
}

interface IPdfInfo {
  fr: string;
  en: string;
  pdf: string;
  icon: IconType;
}

interface ITranslatedPdfInfo {
  content: string;
  pdf: string;
  icon: IconType;
}

export const getCommonQuestions = (
  currentLanguage: "fr" | "en"
): ITranslatedCommonQuestion[] => {
  const commonQuestions: ICommonQuestion[] = [
    {
      fr: "GPS marin avec profondimètre",
      en: "GPS with depth gauge",
      icon: RiGpsLine,
    },
    { fr: "Radio Bluetooth", en: "Bluetooth radio", icon: BiRadio },
    {
      fr: "Toit bimini",
      en: "Bimini top",
      icon: MdOutlineDirectionsBoatFilled,
    },
    {
      fr: "Terrain de camping gratuit sur le site",
      en: "Free campground on site",
      icon: FaCaravan,
    },
    { fr: "Table pour manger", en: "Dining table", icon: GiTable },
    {
      fr: "Échelle pour la baignade",
      en: "Ladder for easy access while swimming",
      icon: FaSwimmingPool,
    },
    {
      fr: "Vestes flottaison 90lbs et + (Vous devez fournir celles pour enfants)",
      en: "Safety vests for 90lbs and more (You must provide child safety vests)",
      icon: FaVest,
    },
    {
      fr: "Service d'accompagnateur disponible sur demande",
      en: "Attendant service available upon request",
      icon: GiCaptainHatProfile,
    },
    {
      fr: "Les animaux sont permis",
      en: "Pets are allowed",
      icon: GiSittingDog,
    },
    {
      fr: "Autorisation de pêche de l’Aire Faunique du Lac St-Pierre",
      en: "Fishing authorization for the Lac St-Pierre Wildlife Area",
      icon: GiFishing,
      link: {
        fr: "Autorisation",
        en: "Authorization",
        icon: BsBoxArrowInUpRight,
        target: "https://afclacst-pierre.org/autorisation-permis-peche/",
      },
    },
    {
      fr: "Carte de conducteur d'embarcation de plaisance nécessaire. Si vous ne l'avez pas, nous pouvons émettre un permis journalier sans frais.",
      en: "Pleasure craft operator card necessary. If you do not have one, we can provide a daily permit at no extra cost",
      icon: AiFillIdcard,
    },
  ];

  return commonQuestions.map((question) => ({
    content: question[currentLanguage],
    icon: question.icon,
    link: question?.link
      ? formatLink(question.link, currentLanguage)
      : undefined,
  }));
};

export const formatLink = (
  linkInfo: ILinkQuestion,
  currentLanguage: "fr" | "en"
): ILinkTranslated => {
  return {
    content: linkInfo[currentLanguage],
    icon: linkInfo.icon,
    target: linkInfo.target,
  };
};
export const getExtraPdfQuestions = (
  currentLanguage: "fr" | "en"
): ITranslatedPdfInfo[] => {
  const pdfInfoArray: IPdfInfo[] = [
    {
      fr: "PDF questions fréquentes",
      en: "Frequently asked questions PDF",
      pdf: questionsPdf,
      icon: BsFileEarmarkPdf,
    },
    {
      fr: "Réglements et conditions",
      en: "Rules and conditions",
      pdf: rulesAndConditionsPdf,
      icon: BsFileEarmarkPdf,
    },
  ];

  return pdfInfoArray.map((info) => ({
    content: info[currentLanguage],
    pdf: info.pdf,
    icon: info.icon,
  }));
};
