import styled from "styled-components";
import { GlobalWrapper } from "../styles/CommonWrapper.styles";

export const PackageWrapper = styled(GlobalWrapper)`
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  margin-bottom: 2%;
`;

export const PackageTitle = styled.h2``;

export const SmallText = styled.p`
  font-size: 0.8em;
`;

export const ObviousText = styled.div`
  font-size: 1.1rem;
  border-radius: 30px;
  color: #024859;
  background: #dcfce7;
  padding: 0.5px 7px;
  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const PackagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  justify-content: center;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
`;
