// styles
import { useTranslation } from "react-i18next";
import {
  HoursWrapper as Wrapper,
  HoursTitle,
  HoursList,
  TimeToggle,
  HourLabel,
  SelectionIndicator,
} from "./hours.styles";

interface IHours {
  hoursArray: number[];
  callback: (value: string) => void;
  currentHour: string;
  id: number;
}

const Hours = ({ hoursArray, callback, currentHour, id }: IHours) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <HoursTitle>{t("hoursTitle")}</HoursTitle>
      <HoursList>
        <SelectionIndicator
          isTopSelected={hoursArray[0].toString() === currentHour}
        >
          {hoursArray[hoursArray.length - 1]} {t("hours")}
        </SelectionIndicator>
        {hoursArray.map((hour, index) => (
          <>
            <HourLabel
              selected={hour.toString() === currentHour}
              htmlFor={`selectedHour - ${id} - ${index}`}
            >
              {hour} {t("hours")}
            </HourLabel>
            <TimeToggle
              id={`selectedHour - ${id} - ${index}`}
              type="radio"
              value={hour}
              name={`selectedHour - ${id}`}
              checked={hour.toString() === currentHour}
              onChange={(ev) => {
                callback(ev.target.value);
              }}
            />
          </>
        ))}
      </HoursList>
    </Wrapper>
  );
};

export default Hours;
