import { Link } from "react-scroll";
import styled from "styled-components";

export const LinksWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-self: center;
  justify-self: center;
  text-shadow: 1px 1px 10px #000000;
  @media (max-width: 760px) {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  :hover {
    span {
      width: 100%;
    }
  }

  @media (max-width: 760px) {
    padding: 0.2em;
    align-items: center;
    :hover {
      span {
        width: 80%;
      }
    }
  }
`;
export const Underline = styled.span`
  width: 0;
  height: 2px;
  background: black;
  transition: 300ms ease;
  opacity: 0.8;
  border-radius: 2px;
  position: absolute;
  top: 82%;
`;
