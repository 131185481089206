import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {
  useContext,
  forwardRef,
  InputHTMLAttributes,
  DetailedHTMLProps,
  useState,
  useEffect,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormContext } from "../../../contexts/FormContext";
import { DateFieldButton, DatePickerWrapper, Label } from "./form-styles";
import fr from "date-fns/locale/fr-CA";
import en from "date-fns/locale/en-CA";
import moment from "moment";
import { frenchDateValues } from "../../../configs/dateConfigs";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

registerLocale("fr", fr);
registerLocale("en", en);

const DateField = () => {
  const [selected, setSelected] = useState(false);
  const { getFieldValue, updateForm } = useContext(FormContext);
  const date = getFieldValue("date");

  useEffect(() => {
    moment.locale("fr");
  }, []);

  const getLocaleDate = () => {
    moment.locale("fr", frenchDateValues);
    return moment(date).format("Do MMMM YYYY");
  };

  return (
    <DatePickerWrapper selected={selected}>
      <DatePicker
        locale={i18n.language}
        selected={date ? new Date(date) : undefined}
        onChange={(date) =>
          updateForm({
            formChange: { field: "date", newValue: date?.toDateString() },
          })
        }
        dateFormat={i18n.language === "fr" ? "d MMMM yyyy" : "MMMM d, yyyy"}
        customInput={<DateFieldInput />}
        onCalendarClose={() => setSelected(false)}
        onCalendarOpen={() => setSelected(true)}
      />
      {date && (
        <input
          style={{ display: "none" }}
          id="date"
          name="date"
          value={getLocaleDate()}
        />
      )}
      <Label htmlFor="date" error={false} hasValue={true}>
        Date
      </Label>
    </DatePickerWrapper>
  );
};

export default DateField;

const DateFieldInput = forwardRef<
  HTMLButtonElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
  const { t } = useTranslation(["reservationForm"]);

  return (
    <DateFieldButton
      className="detail_button"
      onClick={(ev) => {
        ev.preventDefault();
        props.onClick?.(ev);
      }}
      ref={ref}
    >
      {props.value || t("dateSelect")}
    </DateFieldButton>
  );
});
