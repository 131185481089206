import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//styles
import { GlobalStyles } from "./GlobalStyles";

//components
import NavBar from "./content/navigation-bar/NavBar";
import MainPage from "./content/pages/MainPage";

const App = () => {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Router>
      <GlobalStyles />
    </>
  );
};

export default App;
