import { useContext, useState, ChangeEvent } from "react";
import { FormContext } from "../../../contexts/FormContext";
import { EditToggleButton } from "../../footer/emailform.styles";
import { Label, TextArea, TextFieldWrapper } from "./form-styles";
import { ITextField } from "./TextField";
import { AiTwotoneEdit, AiFillSave } from "react-icons/ai";

const TextAreaField = ({ field, fieldLabel, isEditable }: ITextField) => {
  const [isEditing, setIsEditing] = useState(false);
  const { getFieldValue, updateForm } = useContext(FormContext);
  const fieldValue = getFieldValue(field);
  const limit = 500;
  return (
    <TextFieldWrapper>
      {isEditable && (
        <EditToggleButton
          isEditing={isEditing}
          onClick={(ev) => {
            ev.preventDefault();
            setIsEditing(!isEditing);
          }}
        >
          {isEditing ? <AiFillSave /> : <AiTwotoneEdit />}
        </EditToggleButton>
      )}
      <TextArea
        name={field}
        id={field}
        disabled={isEditable && !isEditing}
        value={getFieldValue(field)}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          event.target.style.height = "inherit";
          event.target.style.height = `${
            Math.min(event.target.scrollHeight, limit) + 3
          }px`;
          updateForm({ formChange: { field, newValue: event.target.value } });
        }}
      />
      <Label htmlFor={field} error={false} hasValue={!!fieldValue}>
        {fieldLabel}
      </Label>
    </TextFieldWrapper>
  );
};

export default TextAreaField;
