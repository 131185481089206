import { PhoneNumber } from "../navigation-bar/navbar.styles";
import { IInformationIconObject } from "./footer.configs";
import { formatString } from "./footer.utils";
import {
  InformationIconWrapper as Wrapper,
  InformationTitle,
  InformationLink,
  InformationText,
  IconWrapper,
  InformationPhoneNumber,
} from "./information-icon.styles";
const InformationIcon = ({
  icon,
  title,
  text,
  link,
}: IInformationIconObject) => {
  const formattedText = formatString(text || "");
  return (
    <Wrapper>
      <InformationLink href={link} target="_blank">
        <IconWrapper>{icon}</IconWrapper>
        <InformationTitle>{title}</InformationTitle>
        {formattedText.map((format, index) => {
          if (format.includes("450")) {
            return (
              <InformationPhoneNumber href={`tel:+1-${format}`}>
                {format}
              </InformationPhoneNumber>
            );
          }
          return (
            <InformationText key={`${format}-${index}`}>
              {format}
            </InformationText>
          );
        })}
      </InformationLink>
    </Wrapper>
  );
};

export default InformationIcon;
