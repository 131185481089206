//styles
import { UnStyledButton } from "../../styles/buttons/UnStyledButton";
import { StyledLink, Underline } from "./link.styles";

//types
import { IsiteLinks } from "./Links";

const Link = ({ name, targetId, callback }: IsiteLinks) => {
  return (
    <StyledLink smooth={true} to={targetId}>
      <UnStyledButton onClick={() => callback && callback()}>
        {name}
      </UnStyledButton>
      <Underline />
    </StyledLink>
  );
};

export default Link;
