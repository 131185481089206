import styled from "styled-components";

export const HamWrapper = styled.div`
  justify-self: start;

  @media (min-width: 761px) {
    display: none;
  }
`;

export const DropDownList = styled.ul<{ state: boolean }>`
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: var(--content-color);
  overflow: hidden;
  transition: 400ms ease;
  opacity: ${({ state }) => (state ? "1" : "0")};
  transform: ${({ state }) => (state ? "translateX(0%)" : "translateX(-100%)")};
  border-bottom-right-radius: 3px;
`;
