import behind1 from "./images/pontoon-1/1-behind-1000px.AVIF";
import front1 from "./images/pontoon-1/1-front-1000px.AVIF";
import main1 from "./images/pontoon-1/1-main-1000px.AVIF";
import side1 from "./images/pontoon-1/1-side-1000px.AVIF";

import behind2 from "./images/pontoon-2/2-behind-1000px.AVIF";
import front2 from "./images/pontoon-2/2-front-1000px.AVIF";
import main2 from "./images/pontoon-2/2-main-1000px.AVIF";
import side2 from "./images/pontoon-2/2-side-1000px.AVIF";

import top3 from "./images/pontoon-3/3-top-1000px.AVIF";
import roof3 from "./images/pontoon-3/3-roof-1000px.AVIF";
import main3 from "./images/pontoon-3/3-main-1000px.AVIF";
import side3 from "./images/pontoon-3/3-side-1000px.AVIF";

import top4 from "./images/pontoon-4/4-top-1000px.AVIF";
import front4 from "./images/pontoon-4/4-front-1000px.AVIF";
import main4 from "./images/pontoon-4/4-main-1000px.AVIF";
import behind4 from "./images/pontoon-4/4-behind-1000px.AVIF";

import top5 from "./images/pontoon-5/5-top-1000px.AVIF";
import roof5 from "./images/pontoon-5/5-roof-1000px.AVIF";
import main5 from "./images/pontoon-5/5-main-1000px.AVIF";
import side5 from "./images/pontoon-5/5-side-1000px.AVIF";
import behind5 from "./images/pontoon-5/5-behind-1000px.AVIF";

import main7 from "./images/pontoon-7/7-main-1000px.jpg";
import top7 from "./images/pontoon-7/7-top-1000px.jpg";
import behind7 from "./images/pontoon-7/7-behind-1000px.jpg";
import side7 from "./images/pontoon-7/7-side-1000px.jpg";

// export const pontoon1 = [main1];
// export const pontoon2 = [main2];
// export const pontoon3 = [main3];
// export const pontoon4 = [main4];
// export const pontoon5 = [main5];

export const pontoon1 = [main1, behind1, front1, side1];
export const pontoon2 = [main2, behind2, front2, side2];
export const pontoon3 = [main3, top3, roof3, side3];
export const pontoon4 = [main4, top4, front4, behind4];
export const pontoon5 = [main5, top5, roof5, side5, behind5];
export const pontoon7 = [main7, top7, behind7, side7];
