import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReservationContext } from "../../../../contexts/ReservationContext";
import ReservationEmailForm from "../../form/ReservationEmailForm";
import Modal from "../Modal";
import { Wrapper } from "./reservationModal-styles";
import { FormContext } from "../../../../contexts/FormContext";

const ReservationModal = () => {
  const { t } = useTranslation(["reservationForm"]);
  const { reservation, removeReservation } = useContext(ReservationContext);
  const { isOpen } = useContext(FormContext);
  return (
    <Modal
      title={t("reservationForm:reservation")}
      isOpen={!!reservation}
      handleCloseModal={removeReservation}
      isChildrenOpen={isOpen}
    >
      <Wrapper>{reservation && <ReservationEmailForm />}</Wrapper>
    </Modal>
  );
};

export default ReservationModal;
