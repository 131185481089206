import { useTranslation } from "react-i18next";

//configs
import { createInformationIconObjects } from "./footer.configs";
import { getSiteLinks } from "../../configs/siteLink.config";

//components
import InformationIcon from "./InformationIcon";
import EmailForm from "./EmailForm";

//images
import droneScene003 from "../../assets/images/dronescene003.AVIF";

//styles
import {
  ContentWrapper,
  DarkShadow,
  FooterBottomPart,
  FooterShortText,
  FooterTitle,
  FooterWrapper as Wrapper,
  TitleWrapper,
} from "./footer.styles";
import { InformationsWrapper } from "./information-icon.styles";

const Footer = () => {
  const { t } = useTranslation();
  const infoIcons = createInformationIconObjects(t);

  const footerSiteLink = getSiteLinks(t).contactLink;

  return (
    <Wrapper bgImage={droneScene003} id={footerSiteLink.targetId}>
      <DarkShadow />
      <ContentWrapper>
        <TitleWrapper>
          <FooterTitle>{t("footerTitle")}</FooterTitle>
          <FooterShortText>{t("footerText")}</FooterShortText>
        </TitleWrapper>
        <FooterBottomPart>
          <InformationsWrapper>
            {infoIcons.map(({ icon, title, text, link }, index) => (
              <InformationIcon
                key={`${title}-${index}`}
                icon={icon}
                title={title}
                text={text}
                link={link}
              />
            ))}
          </InformationsWrapper>
          <EmailForm />
        </FooterBottomPart>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;
