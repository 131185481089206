import { useContext, useEffect, useRef, MouseEvent, useState } from "react";
import TextField from "./TextField";
import { Form } from "./form-styles";
import TextAreaField from "./TextAreaField";
import PontoonInfoSection from "./pontoonInfo/PontoonInfoSection";
import SubmitFormField from "./SubmitFormField";
import DateField from "./DateField";
import CloseFormField from "./CloseFormField";
import { FormFooterWrapper } from "../../footer/emailform.styles";
import { ReservationContext } from "../../../contexts/ReservationContext";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { FormContext } from "../../../contexts/FormContext";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeCircles } from "react-loader-spinner";
import { MainStyledButton } from "../../styles/buttons/MainStyledButton";
import { MainStyledLink } from "../../styles/buttons/MainStyledLink";
import PhoneField from "./PhoneField";

const ReservationEmailForm = () => {
  const { t } = useTranslation(["reservationForm"]);
  const { removeReservation } = useContext(ReservationContext);
  const { isLoading, updateLoadingState, isOpen, toggleIsOpen } =
    useContext(FormContext);
  const [savedFormHeight, setSavedFormHeight] = useState(0);
  const form = useRef() as any;

  useEffect(() => {
    const form = document.getElementById("formId");
    const openFormButton = document.getElementById("openFormBtn");
    if (form && openFormButton) {
      setSavedFormHeight(form.clientHeight - openFormButton.clientHeight);
      form.style.height = "245px";
    }
  }, []);

  const handleOpenEmailForm = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    const form = document.getElementById("formId");
    if (form) {
      form.style.height = `${savedFormHeight}px`;
      setTimeout(() => {
        form.style.height = "100%";
      }, 300);
    }
    toggleIsOpen();
  };

  const handleSubmitForm = async (ev: MouseEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (form.current) {
      updateLoadingState(true);
      const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
      const serviceKey = process.env.REACT_APP_EMAIL_SERVICE_KEY;
      const templateKey = process.env.REACT_APP_EMAIL_TEMPLATE_KEY;
      if (publicKey && serviceKey && templateKey) {
        const promise = emailjs.sendForm(
          serviceKey,
          templateKey,
          form.current,
          publicKey
        );
        toast.promise(promise, {
          pending: t("sending"),
          success: `${t("sent")} 👌`,
          error: "ERROR",
        });
        await promise;
      }

      updateLoadingState(false);
      removeReservation();
    }
  };

  return (
    <Form id="formId" isOpen={isOpen} ref={form} onSubmit={handleSubmitForm}>
      <MainStyledLink
        href="tel:+1-450-880-0653"
        style={{ width: "95%", marginLeft: "2.5%" }}
      >
        {t("call")}
      </MainStyledLink>
      {!isOpen && (
        <MainStyledButton
          id="openFormBtn"
          onClick={handleOpenEmailForm}
          style={{ width: "95%", marginLeft: "2.5%", marginTop: "5px" }}
        >
          {t("sendEmail")}
        </MainStyledButton>
      )}
      <PontoonInfoSection />
      <TextField
        type="text"
        field="name"
        fieldLabel={t("reservationForm:name")}
      />
      <PhoneField
        field="telephone"
        fieldLabel={t("reservationForm:telephone")}
      />
      <TextField
        type="email"
        field="email"
        fieldLabel={t("reservationForm:email")}
      />
      <DateField />
      <TextAreaField
        field="message"
        fieldLabel={t("reservationForm:message")}
      />
      <FormFooterWrapper>
        <SubmitFormField />
        <CloseFormField callback={removeReservation} />
      </FormFooterWrapper>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            background: "#3f3e3e40",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ThreeCircles
            height="100"
            width="100"
            color="#a4ffeb"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor="#a4ffeb"
            innerCircleColor="#052821"
            middleCircleColor="#a4ffeb"
          />
        </div>
      )}
    </Form>
  );
};

export default ReservationEmailForm;
