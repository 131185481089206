import { useContext, ChangeEvent } from "react";
import { FormContext, IFormValues } from "../../../contexts/FormContext";
import { TextFieldWrapper, InputField, Label } from "./form-styles";

export interface ICurrencyField {
  field: keyof IFormValues;
  fieldLabel: string;
  disabled?: boolean;
  isEditable?: boolean;
  readonly?: boolean;
  labelExtraInfo?: JSX.Element;
}

const CurrencyField = ({
  field,
  fieldLabel,
  disabled,
  readonly,
  labelExtraInfo,
}: ICurrencyField) => {
  const { getFieldValue, updateForm, promotion } = useContext(FormContext);
  const fieldValue = getFieldValue(field) || "0";
  return (
    <TextFieldWrapper>
      <InputField
        disabled={disabled}
        readOnly={readonly}
        type={"currency"}
        name={field}
        id={field}
        value={`$${Math.floor(+fieldValue * (1 - promotion))}`}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          updateForm({ formChange: { field, newValue: event.target.value } })
        }
      />
      <Label htmlFor={field} error={false} hasValue={!!fieldValue}>
        {fieldLabel}
        {labelExtraInfo}
      </Label>
    </TextFieldWrapper>
  );
};

export default CurrencyField;
