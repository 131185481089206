import { useContext, ChangeEvent } from "react";
import { FormContext, IFormValues } from "../../../contexts/FormContext";
import { TextFieldWrapper, InputField, Label } from "./form-styles";

export interface IPhoneField {
  field: keyof IFormValues;
  fieldLabel: string;
  disabled?: boolean;
  isEditable?: boolean;
  readonly?: boolean;
}

const PhoneField = ({ field, fieldLabel, disabled, readonly }: IPhoneField) => {
  const { getFieldValue, updateForm } = useContext(FormContext);
  const fieldValue = getFieldValue(field);

  const formatPhoneNumber = (phoneNumber: string) => {
    let phoneNumberString = phoneNumber.replace(/\D/g, "").substring(0, 10); //Strip everything but 1st 10 digits
    const size = phoneNumberString.length;
    if (size > 0) {
      phoneNumberString = "(" + phoneNumberString;
    }
    if (size > 3) {
      phoneNumberString =
        phoneNumberString.slice(0, 4) + ") " + phoneNumberString.slice(4);
    }
    if (size > 6) {
      phoneNumberString =
        phoneNumberString.slice(0, 9) + "-" + phoneNumberString.slice(9);
    }
    return phoneNumberString;
  };

  return (
    <TextFieldWrapper>
      <InputField
        disabled={disabled}
        readOnly={readonly}
        type={"text"}
        name={field}
        id={field}
        value={fieldValue}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          updateForm({
            formChange: {
              field,
              newValue: formatPhoneNumber(event.target.value),
            },
          })
        }
      />
      <Label htmlFor={field} error={false} hasValue={!!fieldValue}>
        {fieldLabel}
      </Label>
    </TextFieldWrapper>
  );
};

export default PhoneField;
