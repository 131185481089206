import { useState } from "react";

import {
  DropDownButton,
  DropDownList,
  DropDownWrapper as Wrapper,
  Label,
} from "./dropDown-styles";
import { IoIosArrowDown } from "react-icons/io";
import DropDownItem from "./DropDownItem";

export interface IDropDownItem {
  enLabel: string;
  frLabel: string;
  [propName: string]: string;
  value: string;
}

interface IDropDownProps {
  dropDownItems: IDropDownItem[];
  currentValueInLabel: string;
  callBack: (value: string) => void;
}

const DropDown = ({
  dropDownItems,
  currentValueInLabel,
  callBack,
}: IDropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const changeDropDownState = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper isOpen={isOpen}>
      <Label>
        {currentValueInLabel}
        <DropDownButton onClick={changeDropDownState} isOpen={isOpen}>
          <IoIosArrowDown />
        </DropDownButton>
      </Label>
      <DropDownList isOpen={isOpen}>
        {dropDownItems.map((item, index) => {
          return (
            <DropDownItem
              key={index}
              handleCallBackFunction={callBack}
              item={item}
              closeDropDown={changeDropDownState}
            />
          );
        })}
      </DropDownList>
    </Wrapper>
  );
};

export default DropDown;
