import { createContext, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ISiteItem } from "../assets/items";
import {
  formulateReservationMessage,
  formulateReservationName,
} from "../utils/form.utils";
import { FormContext } from "./FormContext";

interface IReservationValues {
  reservation: IReservation | undefined;
  addReservation: (pontoon: IReservation) => void;
  removeReservation: () => void;
}

export interface IReservation extends Omit<ISiteItem, "prices|hours"> {
  price: number;
  hour: number;
}

interface IPontoonImages {
  pontoonImages: string[];
  selectPontoon: (images: string[]) => void;
  removePontoon: () => void;
}

type IInitialValues = IReservationValues & IPontoonImages;

const initialValues: IInitialValues = {
  reservation: undefined,
  addReservation: () => {},
  removeReservation: () => {},
  pontoonImages: [],
  selectPontoon: () => {},
  removePontoon: () => {},
};

export const ReservationContext = createContext(initialValues);

const ReservationProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(["reservationForm"]);
  const { updateForm, toggleIsOpen, isOpen } = useContext(FormContext);

  const [reservation, setReservation] = useState<IReservation | undefined>(
    undefined
  );
  const [pontoonImages, setPontoonImages] = useState<string[]>([]);

  const addReservation = (pontoon: IReservation): void => {
    updateForm({
      formChange: [
        {
          field: "pontoonName",
          newValue: formulateReservationName(t, pontoon),
        },
        { field: "pontoonPrice", newValue: pontoon.price.toString() },
        { field: "pontoonHour", newValue: pontoon.hour.toString() },
        { field: "message", newValue: formulateReservationMessage(t, pontoon) },
      ],
    });

    setReservation(pontoon);
  };

  const removeReservation = (): void => {
    if (isOpen) {
      toggleIsOpen();
    }
    setReservation(undefined);
  };

  const reservationValues = {
    reservation,
    addReservation,
    removeReservation,
  };

  const selectPontoon = (images: string[]): void => {
    setPontoonImages(images);
  };

  const removePontoon = (): void => {
    setPontoonImages([]);
  };

  const pontoonImagesValues = { pontoonImages, selectPontoon, removePontoon };

  return (
    <ReservationContext.Provider
      value={{ ...reservationValues, ...pontoonImagesValues }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
