import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormContext } from "../../../contexts/FormContext";
import { SubmitButton } from "../../footer/emailform.styles";

export const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const validPhoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
const SubmitFormField = () => {
  const { t } = useTranslation(["reservationForm"]);
  const { getFieldValue, isLoading } = useContext(FormContext);

  const email = getFieldValue("email");
  const name = getFieldValue("name");
  const telephone = getFieldValue("telephone");
  const date = getFieldValue("date");

  const isFormAbleToSubmit = (): boolean => {
    if (email && name && telephone && date) {
      if (
        validEmailRegex.test(email) &&
        validPhoneNumberRegex.test(telephone)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <SubmitButton disabled={!isFormAbleToSubmit() || isLoading}>
      {t(`reservationForm:${isLoading ? "sending" : "complete"}`)}
    </SubmitButton>
  );
};

export default SubmitFormField;
