//styles
import { useTranslation } from "react-i18next";
import { MdAnchor } from "react-icons/md";
import {
  LogoStyles,
  Line,
  Text,
  PhoneNumber,
  PhoneNumberWrapper,
} from "./navbar.styles";

const Logo = () => {
  const { t } = useTranslation();

  return (
    <LogoStyles>
      <MdAnchor size={30} />
      <Line />
      <Text>
        <span>LOCATION </span>
        <span>MARINE </span>
        <span>PIERREVILLE</span>
        <PhoneNumberWrapper>
          <PhoneNumber href={`tel:+1-${t("phoneNumber1")}`}>
            {t("phoneNumber1")}
          </PhoneNumber>
          <span>{t("or")}</span>
          <PhoneNumber href={`tel:+1-${t("phoneNumber2")}`}>
            {t("phoneNumber2")}
          </PhoneNumber>
        </PhoneNumberWrapper>
      </Text>
    </LogoStyles>
  );
};

export default Logo;
