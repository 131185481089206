import i18n from "i18next";
import { IDropDownItem } from "./DropDown";

// styles
import { LanguageItem, LanguageChangeButton } from "./dropDown-styles";

interface IDropDownItemProps {
  item: IDropDownItem;
  handleCallBackFunction: (value: string) => void;
  closeDropDown: () => void;
}
const DropDownItem = ({
  item,
  handleCallBackFunction,
  closeDropDown,
}: IDropDownItemProps) => {
  return (
    <LanguageItem>
      <LanguageChangeButton
        onClick={(ev) => {
          handleCallBackFunction(item.value);
          closeDropDown();
        }}
      >
        {item?.[`${i18n.language.split("-")[0]}Label`]}
      </LanguageChangeButton>
    </LanguageItem>
  );
};

export default DropDownItem;
