import { useTranslation } from "react-i18next";
import TextField from "../TextField";
import { DetailWrapper, Wrapper } from "./pontoonInfo-styles";
import { isFriday, isWeekend } from "date-fns";
import moment from "moment-holiday";
import { useContext, useEffect } from "react";
import { FormContext } from "../../../../contexts/FormContext";
import { LabelExtraInfo } from "../form-styles";
import CurrencyField from "../CurrencyField";
import { ReservationContext } from "../../../../contexts/ReservationContext";
import { WATERCRAFT_TYPES } from "../../../../assets/items";

const PontoonInfoSection = () => {
  const { t } = useTranslation(["reservationForm"]);
  const { getFieldValue, updatePromotion } = useContext(FormContext);

  const { reservation } = useContext(ReservationContext);

  const dateString = getFieldValue("date");
  const date = dateString && new Date(dateString);
  const isPromotionActive = date && !isFriday(date) && !isWeekend(date);

  const isKayak = reservation?.type === WATERCRAFT_TYPES.KAYAK;

  useEffect(() => {
    if (date) {
      updatePromotion(isPromotionActive ? 0.2 : 0);
    }
  }, [dateString, isPromotionActive]);

  return (
    <Wrapper>
      <TextField
        field="pontoonName"
        fieldLabel={t("reservationForm:reservationName")}
        readonly
      />
      <DetailWrapper>
        <CurrencyField
          field="pontoonPrice"
          fieldLabel={t("reservationForm:price")}
          labelExtraInfo={
            isPromotionActive && !isKayak ? (
              <LabelExtraInfo>{t("promotionText")}</LabelExtraInfo>
            ) : undefined
          }
          readonly
        />
        <TextField
          field="pontoonHour"
          fieldLabel={t("reservationForm:hour")}
          readonly
        />
      </DetailWrapper>
    </Wrapper>
  );
};

export default PontoonInfoSection;
