import styled from "styled-components";
import { UnStyledHyperLink } from "../styles/buttons/UnstyledHyperLink";

export const InformationsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "icon icon"
    "icon icon";
  row-gap: 20px;
  flex-grow: 1;
  max-width: 400px;
  @media (max-width: 760px) {
    max-width: 100%;
  }
  @media (max-width: 360px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InformationIconWrapper = styled.div`
  align-self: start;

  @media (max-width: 360px) {
    align-self: center;
  }
`;

export const InformationTitle = styled.h3``;

export const InformationLink = styled.a`
  color: var(--off-white);
  text-shadow: 0 0 8px black;
  text-decoration: none;
  font-size: 1.1em;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 360px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const InformationText = styled.p`
  font-size: 0.8em;
`;

export const InformationPhoneNumber = styled(UnStyledHyperLink)`
  font-size: 0.8em;
  text-decoration: none;
`;

export const IconWrapper = styled.div`
  padding: 15px;
  border-radius: 55px;
  background: var(--heighlight-color);
  width: fit-content;
  color: black;
`;
