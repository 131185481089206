import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

interface IPriceTage {
  value: string;
}

const PriceTag = ({ value }: IPriceTage) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(true);

    setTimeout(() => {
      setToggle(false);
    }, 900);
  }, [value]);

  return <BounceValue toggle={toggle}>{value}</BounceValue>;
};

const bounceIn = keyframes`

    0%{
      transform:scale(1);
      color:green;
    }
  25%{
    transform:translateX(20%) scale(1.5);
  }
  50%{
    transform:scale(1);

  }
 

`;

const BounceValue = styled.div<{ toggle: boolean }>`
  animation: 1s ${bounceIn} ease infinite;
  animation-fill-mode: forwards;
  animation-play-state: ${({ toggle }) => (toggle ? "running" : "paused")};
`;

export default PriceTag;
