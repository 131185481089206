import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSiteLinks } from "../../../configs/siteLink.config";
import HamburgerButton from "../../styles/buttons/HamburgerButton";
import Link from "../siteLinks/Link";
import { IsiteLinksObject } from "../siteLinks/Links";
import { HamWrapper as Wrapper, DropDownList } from "./hamburger.styles";

const HamburgerDropDown = () => {
  const { t } = useTranslation();
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);

  const siteLinks: IsiteLinksObject = getSiteLinks(t);

  const changeBurgerIsOpenState = () => {
    setBurgerIsOpen(!burgerIsOpen);
  };

  return (
    <Wrapper>
      <HamburgerButton
        callback={changeBurgerIsOpenState}
        state={burgerIsOpen}
      />
      <DropDownList state={burgerIsOpen}>
        {Object.values(siteLinks).map(({ name, targetId }) => (
          <Link
            key={name}
            name={name}
            targetId={targetId}
            callback={changeBurgerIsOpenState}
          />
        ))}
      </DropDownList>
    </Wrapper>
  );
};

export default HamburgerDropDown;
