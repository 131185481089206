import styled from "styled-components";
import { MainStyledButton } from "../styles/buttons/MainStyledButton";
import { GlobalWrapper } from "../styles/CommonWrapper.styles";
import { Link } from "react-scroll";

export const HeaderWrapper = styled.div`
  height: 100%;
`;

export const VideoWrapper = styled(GlobalWrapper)`
  overflow: hidden;
  height: fit-content;
  max-height: 600px;
  display: flex;
  align-items: center;
  justify-content: start;
  box-shadow: var(--sharp-shadow);
  background: black;
  @media (max-width: 1150px) {
    top: 25px;
  }
`;

export const VideoPlayer = styled.video`
  width: 100%;
  height: auto;
  filter: brightness(0.8);
  @media (max-width: 760px) {
    width: 250%;
  }
`;

export const HeaderWrapperCTA = styled(GlobalWrapper)`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  @media (max-width: 1150px) {
    margin: 50px 0;
  }
`;

export const HeaderContentWrapper = styled(GlobalWrapper)`
  position: relative;
  display: flex;
  max-width: 1300px;
  gap: 20px;
  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    color: var(--off-white);
    max-width: 400px;
    height: 95%;
    text-shadow: 1px 1px 5px #1a1a1a;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 5px;
  }
`;

export const HeaderTitle = styled.h1`
  width: 100%;
  position: absolute;
  color: var(--off-white);
  text-shadow: 1px 1px 5px #1a1a1a;
  text-align: center;
  margin-top: 80px;
  z-index: 2;
  @media (max-width: 750px) {
    margin-top: 60px;
  }
  @media (max-width: 360px) {
    font-size: 23px;
    line-height: 1.3;
  }
`;

export const HeaderDescriptionTitle = styled.h2`
  margin-bottom: 30px;
`;

export const HeaderDescription = styled.p`
  text-align: start;
  margin-bottom: 20px;
`;

export const HeaderCenterImage = styled.img`
  box-shadow: var(--sharp-shadow);
  margin-left: 5px;
  @media (max-width: 600px) {
    object-position: right top;
  }
  @media (max-width: 800px) {
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 1100px) {
    margin-left: 0;
  }
`;

export const ImageWrapper = styled.div`
  filter: brightness(1.3);
  border-radius: 2px;

  @media (max-width: 1100px) {
    overflow: hidden;
    box-shadow: var(--sharp-shadow);
    filter: brightness(0.6);
    position: absolute;
    top: 0;
    right: 0;
    bottom: -7px;
    left: 0;
    z-index: -5;
  }
`;

export const HeaderCenterWrapper = styled.div``;

export const PriceButton = styled(MainStyledButton)`
  font-size: 15px;
`;

export const StyledLink = styled(Link)`
  align-self: center;
  margin-top: 6%;
`;
