import styled from "styled-components";
import { GlobalWrapper } from "../styles/CommonWrapper.styles";

export const FooterWrapper = styled(GlobalWrapper)<{ bgImage: string }>`
  transition: 300ms;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  filter: contrast(150%);
  padding: min(50px, 5%);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1100px) {
    align-items: inherit;
  }
`;
export const DarkShadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: #212121;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const FooterBottomPart = styled.div`
  display: flex;
  padding-bottom: min(100px, 5%);
  @media (max-width: 760px) {
    flex-direction: column;
  }

  gap: 20px;
`;

export const FooterTitle = styled.h2`
  letter-spacing: 1.2px;
`;

export const FooterShortText = styled.p``;

export const TitleWrapper = styled.div`
  color: var(--off-white);
  text-shadow: 0 0 5px #4e4e4e;
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  min-width: 1000px;
  @media (max-width: 1100px) {
    min-width: auto;
  }
`;
