import { useTranslation } from "react-i18next";
import { BsFillQuestionCircleFill, BsFileEarmarkPdf } from "react-icons/bs";
//styles
import {
  FrequentlyAskedQuestionsWrapper as Wrapper,
  FrequentlyAskedQuestionsTitle as Title,
  CommonQuestionsList,
  CommonQuestion,
  MoreQuestionsBox,
  MoreQuestionFilesWrapper,
  FileDownloadLink,
  FileTitle,
} from "./frequentlyAskedQuestions-styles";

//configs
import { getSiteLinks } from "../../configs/siteLink.config";
import {
  getCommonQuestions,
  getExtraPdfQuestions,
} from "./commonQuestions.config";
import QuestionLink from "./QuestionLink";

const FrequentlyAskedQuestions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const productSiteLink = getSiteLinks(t).productsLink;

  const translatedCommonQuestions = getCommonQuestions(
    language === "fr" ? "fr" : "en"
  );

  const translatedPdfInfo = getExtraPdfQuestions(
    language === "fr" ? "fr" : "en"
  );

  return (
    <Wrapper id={productSiteLink.targetId}>
      <Title>{t("frequentlyAskedQuestionTitle")}</Title>
      <CommonQuestionsList>
        {translatedCommonQuestions.map((question) => {
          const link = question.link;
          return (
            <CommonQuestion>
              {question.icon({ size: "35px" })}
              {question.content}
              {link && <QuestionLink link={link} />}
            </CommonQuestion>
          );
        })}
        <MoreQuestionsBox>
          <BsFillQuestionCircleFill size="35px" />
          {t("moreQuestionsTitle")}
          <MoreQuestionFilesWrapper>
            {translatedPdfInfo.map((info) => (
              <FileDownloadLink
                target="#"
                href={info.pdf}
                download={`${t("siteName")} - ${info.content}`}
              >
                {info.icon({ size: "20px" })}
                <FileTitle>{info.content}</FileTitle>
              </FileDownloadLink>
            ))}
          </MoreQuestionFilesWrapper>
        </MoreQuestionsBox>
      </CommonQuestionsList>
    </Wrapper>
  );
};

export default FrequentlyAskedQuestions;
