import { ReactElement } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Wrapper, Content, CloseButton, Header } from "./modal-styles";
import { IoCloseCircleOutline } from "react-icons/io5";

interface IModal {
  isOpen: boolean;
  handleCloseModal: () => void;
  title?: string;
  children: ReactElement;
  isChildrenOpen?: boolean;
}

const Modal = ({
  isOpen,
  handleCloseModal,
  title,
  children,
  isChildrenOpen,
}: IModal) => {
  return (
    <Wrapper isOpen={isOpen}>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={handleCloseModal}>
          <Content isContentOpen={!!isChildrenOpen}>
            <Header isLabelPresent={!!title}>
              {title}
              <CloseButton onClick={handleCloseModal}>
                <IoCloseCircleOutline size={22} />
              </CloseButton>
            </Header>
            {children}
          </Content>
        </OutsideClickHandler>
      )}
    </Wrapper>
  );
};

export default Modal;
