import { IconType } from "react-icons";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";

export interface IInformationIconObject {
  icon: JSX.Element;
  title: string;
  text?: string;
  link?: string;
}

export const createInformationIconObjects = (
  t: Function
): IInformationIconObject[] => {
  const size = 22;
  return [
    {
      icon: <AiOutlineMail size={size} />,
      title: t("email"),
      text: t("emailText"),
      link: "mailto:pierreville.ponton@gmail.com",
    },
    {
      icon: <FiPhoneCall size={size} />,
      title: t("phone"),
      text: t("phoneNumber"),
    },
    {
      icon: <GoLocation size={size} />,
      title: t("location"),
      text: t("locationInfo"),
      link: "https://www.google.com/maps/place/Location+ponton/@46.1052317,-72.8882855,15z/data=!4m5!3m4!1s0x4cc87e4972ad652b:0xa41056050f7c5ec9!8m2!3d46.1066087!4d-72.8827404",
    },
    {
      icon: <FaFacebookF size={size} />,
      title: t("facebook"),
      text: t("facebookText"),
      link: "https://www.facebook.com/locationpontonpierreville/?ref=page_internal",
    },
  ];
};
