import { setISODay } from "date-fns";
import { createContext, useState, ReactElement } from "react";

export interface IFormValues {
  name: string;
  telephone: string;
  email: string;
  message: string;
  pontoonName: string;
  pontoonPrice: string;
  pontoonHour: string;
  date: string;
}

interface IFormChanges {
  field: keyof IFormValues;
  newValue: string | undefined;
}

interface IUpdateForm {
  formChange: IFormChanges | IFormChanges[];
}

interface IInitialValues {
  isLoading: boolean;
  isOpen: boolean;
  formValues: Partial<IFormValues>;
  promotion: number;
  updateForm: (props: IUpdateForm) => void;
  getFieldValue: (field: keyof IFormValues) => string | undefined;
  updateLoadingState: (value: boolean) => void;
  updatePromotion: (value: IInitialValues["promotion"]) => void;
  toggleIsOpen: () => void;
}

const initialFormValues: Partial<IFormValues> = {};

const initialValues: IInitialValues = {
  isLoading: false,
  isOpen: false,
  formValues: initialFormValues,
  promotion: 0,
  updateForm: () => {},
  getFieldValue: () => undefined,
  updateLoadingState: () => undefined,
  updatePromotion: () => undefined,
  toggleIsOpen: () => undefined,
};

export const FormContext = createContext(initialValues);

const FormProvider = ({ children }: { children: ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [promotion, setPromotion] = useState<number>(0);
  const [formValues, setFormValues] = useState<Partial<IFormValues>>({});

  const updateForm = ({ formChange }: IUpdateForm) => {
    if (formChange instanceof Array) {
      const changes: Partial<IFormValues> = {};
      formChange.forEach((change) => {
        changes[change.field] = change.newValue;
      });
      setFormValues({ ...formValues, ...changes });
    } else {
      setFormValues({ ...formValues, [formChange.field]: formChange.newValue });
    }
  };

  const getFieldValue = (field: keyof IFormValues): string | undefined => {
    return formValues[field];
  };

  const updateLoadingState = (newValue: boolean) => {
    setIsLoading(newValue);
  };

  const updatePromotion = (newPromo: IInitialValues["promotion"]) => {
    setPromotion(newPromo);
  };

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <FormContext.Provider
      value={{
        isLoading,
        isOpen,
        formValues,
        promotion,
        updateForm,
        getFieldValue,
        updateLoadingState,
        updatePromotion,
        toggleIsOpen,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
