import { ILinkTranslated } from "./commonQuestions.config";
import { FileDownloadLink, FileTitle } from "./frequentlyAskedQuestions-styles";

interface IQuestionLink {
  link: ILinkTranslated;
}

const QuestionLink = ({ link: { content, target, icon } }: IQuestionLink) => {
  return (
    <FileDownloadLink target="#" href={target}>
      <FileTitle style={{ paddingRight: "5px" }}>{content}</FileTitle>
      {icon({ size: "20px" })}
    </FileDownloadLink>
  );
};

export default QuestionLink;
