import styled from "styled-components";
import { UnStyledButton } from "../styles/buttons/UnStyledButton";

export const DropDownWrapper = styled.div<{ isOpen: boolean }>`
  justify-self: end;
  align-self: center;
  width: 58px;
  position: relative;
  margin: 1%;
  padding: 5px 2px 5px 3px;
  border-radius: ${({ isOpen }) => (isOpen ? "2px 2px 0px 0px" : "2px")};
  border: 1px solid black;
  background: ${({ isOpen }) => (isOpen ? "var(--off-white)" : `inherit`)};
  @media (max-width: 760px) {
    margin-right: 5px;
  }
  z-index: 2000;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-around;
  font-size: 0.7em;
`;

export const DropDownList = styled.ul<{ isOpen: boolean }>`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 0.7em;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: var(--off-white);
  width: 58px;
  left: -1px;
  top: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid black;
  border-top: none;
  transition: 200ms ease;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0%) scale(1, 1)" : "translateY(-40%) scale(1, 0.2)"};
`;

export const LanguageItem = styled.li``;

export const LanguageChangeButton = styled(UnStyledButton)`
  width: 100%;
  padding: 3px 3px;
  transition: 200ms ease;
  &:hover {
    background: #c1d6ed;
  }
`;

export const DropDownButton = styled(UnStyledButton)<{ isOpen: boolean }>`
  margin-left: 3px;
  padding: 0;
  transition: 200ms ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0deg)")};
`;
