import styled from "styled-components";
import { UnStyledButton } from "./UnStyledButton";

export const MainStyledButton = styled(UnStyledButton)`
  background: var(--heighlight-color);
  padding: 9px 20px;
  border-radius: 3px;
  box-shadow: var(--light-shadow);
  transition: 300ms ease;
  font-size: 15px;
  text-shadow: 1px 1px 3px #505050;
  color: #fff;

  :hover {
    transform: scale(1.03);
  }
`;
