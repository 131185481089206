import i18n from "i18next";
import { ReactElement, useState } from "react";
import HamburgerDropDown from "./hamburgerSiteLinks/HamburgerDropDown";
import Logo from "./Logo";
import { NavWrapper as Wrapper } from "./navbar.styles";
import Links from "./siteLinks/Links";
import DropDown, { IDropDownItem } from "../common-components/DropDown";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const [currentLang, setCurrentLang] = useState<string>(
    i18n.language.split("-")[0].toUpperCase()
  );
  const { t } = useTranslation();

  const renderLanguageChangeDropDown = (): ReactElement => {
    const languages: IDropDownItem[] = [
      { enLabel: t("en"), frLabel: t("en"), value: "en" },
      { enLabel: t("fr"), frLabel: t("fr"), value: "fr" },
    ];

    const changeSiteLanguage = (value: string): void => {
      i18n.changeLanguage(value);
      setCurrentLang(value.toUpperCase());
    };

    return (
      <DropDown
        currentValueInLabel={currentLang}
        dropDownItems={languages}
        callBack={changeSiteLanguage}
      />
    );
  };

  return (
    <Wrapper>
      <HamburgerDropDown />
      <Logo />
      <Links />
      {renderLanguageChangeDropDown()}
    </Wrapper>
  );
};

export default NavBar;
