import { ReactElement } from "react";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";

interface IFadeInAndOut {
  children: ReactElement;
  state: boolean;
}

const FadeInAndOut = ({ children, state }: IFadeInAndOut) => {
  const transition = useTransition(state, {
    from: {
      opacity: "1",
    },
    enter: {
      opacity: "1",
    },
  });

  return transition(
    (style, item) => item && <Animated style={style}>{children}</Animated>
  );
};

const Animated = styled(animated.div)``;

export default FadeInAndOut;
