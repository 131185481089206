//styles
import {
  HeaderWrapper as Wrapper,
  HeaderTitle as Title,
} from "./header.styles";

//components
import BackgroundVideo from "./BackgroundVideo";
import HeaderCTA from "./HeaderCTA";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Title>
          {t("siteGreeting")} {t("siteName")}
        </Title>
        <BackgroundVideo />
      </Wrapper>
      <HeaderCTA />
    </>
  );
};

export default Header;
